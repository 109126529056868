export function keyNumberFilter(button) {
    const parentDiv = button.closest('.section.capital.key-figure');
    if (parentDiv) {
        parentDiv.querySelectorAll('.tag-list > button').forEach(btn => {
            btn.classList.remove('tag-list-button-is-active');
        });
    }
    button.classList.add('tag-list-button-is-active');
    callKeyNumber(button.getAttribute('data-parent'), button.getAttribute('data-year'), parentDiv);
}
window.keyNumberFilter = keyNumberFilter;

function keyNumberOptionFilter(select) {
    const parentDiv = select.closest('.section.capital.key-figure');
    const selectedOption = select.children[select.selectedIndex];
    callKeyNumber(selectedOption.getAttribute('data-parent'), selectedOption.getAttribute('data-year'), parentDiv);
}
window.keyNumberOptionFilter = keyNumberOptionFilter;

function callKeyNumber(parent, year, parentDiv) {
    const url = window.location.pathname;
    const lang = url.startsWith('/en/') ? '/en' : '';

    fetch(`${lang}/chiffre-cles/${parent}/${year}`)
        .then(response => response.text())
        .then(data => {
            if (parentDiv) {
                parentDiv.querySelector("#key-number-list").innerHTML = data;
            }
        });
}

document.addEventListener('DOMContentLoaded', function() {
    // Traitement du bouton chiffre clé finance
    document.getElementById('filtreChiffre')?.addEventListener('click', function() {
        const annee = document.querySelector("input[name='exampleRadio1']:checked").value;
        const parent = document.getElementById("parentChiffre").value;
        this.parentElement.parentElement.classList.remove('open');

        const url = window.location.pathname;
        const urlsParts = url.split('/');
        const lang = (urlsParts.length > 0 && urlsParts[1] === 'en') ? "/en" : "";

        fetch(`${lang}/chiffre-cles/${parent}/${annee}`)
            .then(response => response.text())
            .then(data => {
                document.getElementById("myDiv").innerHTML = data;
                document.getElementById('chiffreCleA').innerHTML = annee;
            });
    });

    // Traitement du bouton Resultat financier
    document.getElementById('filtreResultat')?.addEventListener('click', function() {
        const annee = document.querySelector("input[name='resultatRadio']:checked").value;
        const parent = document.getElementById("parentResultat").value;
        this.parentElement.parentElement.classList.remove('open');

        const url = window.location.pathname;
        const urlsParts = url.split('/');
        const lang = (urlsParts.length > 0 && urlsParts[1] === 'en') ? "/en" : "";

        fetch(`${lang}/resultats-financiers/${parent}/${annee}`)
            .then(response => response.text())
            .then(data => {
                document.getElementById("myDivResultat").innerHTML = data;
                document.getElementById('resultatFiA').innerHTML = annee;
            });
    });

    // Traitement select changement de langue
    document.querySelectorAll('.changeLangue').forEach(select => {
        select.addEventListener('change', function() {
            window.location.href = this.value;
        });
    });

    // Traitement du formulaire de tri
    document.getElementById('form-tri')?.addEventListener('click', function(e) {
        e.preventDefault();
        const valueSelected = this.parentElement.querySelector("input[name='sort']:checked").value;
        const url = new URL(window.location.href);
        url.searchParams.set('sort', valueSelected);
        window.location.href = url.href;
    });
});
